<script setup lang="ts">
const user = useAuth().data
/* TODO:
 * - Use subgrid to organize the elements by row
 */
const { menuOpen, toggleMenu } = useHeader()
const menuToggle = ref()

const localePath = useLocalePath()
const { partner } = usePromo()
</script>

<template>
  <header
    class="grid h-36 grid-cols-[8rem_1fr] grid-rows-[3.5rem_3rem_2.5rem] md:h-32 md:grid-cols-[12rem_1fr_auto] md:grid-rows-2 md:gap-y-3 md:pt-3"
  >
    <NuxtLink
      :to="localePath('/')"
      class="order-1 flex shrink-0 items-center pl-2 md:pl-6"
      no-prefetch
    >
      <NuxtImg
        src="https://cdn.jagofon.com/logo/logo-green.svg"
        alt="Jagofon Logo"
        class="size-full object-contain object-left"
        preload
      />
    </NuxtLink>
    <div
      class="order-3 col-span-2 bg-gray-800 p-2 md:order-2 md:col-span-1 md:bg-white md:p-0 md:pl-6"
    >
      <HeaderSearch class="size-full" />
    </div>
    <div class="order-2 flex justify-end gap-6 px-2 md:order-3 md:px-6">
      <UButton
        :to="localePath('/about-us')"
        variant="link"
        color="black"
        trailing-icon="i-fal-shield-check"
        size="xl"
        :padded="false"
        no-prefetch
        :aria-label="$t('navigation.about-us')"
      >
        <span class="hidden md:inline">{{ $t('navigation.about-us') }}</span>
      </UButton>
      <UButton
        :to="localePath('/checkout/cart')"
        variant="link"
        color="black"
        trailing-icon="i-fal-cart-shopping"
        size="xl"
        :padded="false"
        no-prefetch
        :aria-label="$t('navigation.cart')"
      >
        <span class="hidden md:inline">{{ $t('navigation.cart') }}</span>
      </UButton>
      <UButton
        :to="localePath('/account')"
        variant="link"
        color="black"
        trailing-icon="i-fal-user"
        size="xl"
        :padded="false"
        no-prefetch
        :aria-label="$t('navigation.account')"
      >
        <span class="hidden md:inline">{{
          user ? useUser().getFirstName(user) : $t('navigation.account')
        }}</span>
      </UButton>
    </div>
    <div
      class="order-4 flex items-center bg-gray-800 pb-2 pl-2 md:pb-0 md:pl-6"
    >
      <UButton
        ref="menuToggle"
        :label="$t('navigation.all-products')"
        variant="link"
        color="white"
        size="lg"
        class="hover:no-underline"
        :padded="false"
        :icon="menuOpen ? 'i-far-xmark' : 'i-far-bars'"
        :ui="{
          icon: {
            size: {
              lg: 'size-5',
            },
          },
        }"
        @click="toggleMenu"
      />

      <HeaderMenu v-if="menuOpen" :ignore="[menuToggle]" />
    </div>
    <div class="order-5 hidden items-center gap-6 bg-gray-800 pl-6 md:flex">
      <UButton
        :to="localePath('/flash-sale')"
        :label="$t('global.flash-sale')"
        variant="link"
        color="white"
        size="xl"
        :padded="false"
        no-prefetch
      />
      <UButton
        :to="localePath('/resale/trade-in')"
        :label="$t('navigation.trade-in')"
        variant="link"
        color="white"
        size="xl"
        :padded="false"
        no-prefetch
      />
      <UButton
        :to="localePath('/resale/buyback')"
        :label="$t('navigation.sell-your-phone')"
        variant="link"
        color="white"
        size="xl"
        :padded="false"
        no-prefetch
      />
    </div>
    <div
      class="order-6 flex items-stretch justify-end gap-3 bg-gray-800 pb-2 pr-2 md:py-2 md:pr-6"
    >
      <UButton
        v-if="partner === 'gojek'"
        :label="$t('navigation.promotion-gojek')"
        variant="link"
        color="primary"
        size="lg"
        class="hover:no-underline"
        :padded="false"
        :to="localePath('/promo/gopartner')"
        no-prefetch
      />
      <UButton
        v-if="partner === 'grab'"
        :label="$t('navigation.promotion-grab')"
        variant="link"
        color="primary"
        size="lg"
        class="hover:no-underline"
        :padded="false"
        :to="localePath('/promo/grab-benefits')"
        no-prefetch
      />
      <UILanguageSelector />
      <UButton
        to="https://wa.me/6281230007451"
        variant="soft"
        color="gray"
        target="_blank"
        aria-label="Jagofon WhatsApp Link"
      >
        <UIcon name="i-fab-whatsapp" class="size-5 text-[#25d366]" />
      </UButton>
    </div>
  </header>
</template>
